import React, { useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Auth } from 'aws-amplify';
import { ErrorBoundary } from 'react-error-boundary';

import AppContext from '../context';
import logger from './logger';
import { logReactErrBoundaryError } from '.';
import FallbackOnError from '../components/FallbackOnError';

function Logout() {
  const { logout } = useAuth0();
  const { setAuthenticated, setShouldLogOut, shouldLogOut } = useContext(AppContext);

  const logoutWithRedirect = () => logout({
    logoutParams: {
      returnTo: window.location.origin,
    },
  });

  useEffect(() => {
    async function doFedSignOut() {
      Auth.signOut().then(() => {
        setAuthenticated(false);
        logoutWithRedirect();
      });
    }

    doFedSignOut();
  }, [shouldLogOut, setShouldLogOut]);

  return (
    <ErrorBoundary
      FallbackComponent={FallbackOnError}
      onError={logReactErrBoundaryError}
      onReset={(details) => {
        logger.info('details', details);
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      <div>Logging out</div>
    </ErrorBoundary>
  );
}

export default Logout;
