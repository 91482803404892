import React from 'react';

const AppContext = React.createContext({
  authenticated: false,
  setAuthenticated: () => {},
  setShouldLogOut: () => {},
  shouldLogOut: false,
});

export default AppContext;
